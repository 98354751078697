export default class UserTempLeave{
    constructor(params = {}){
        const {
            id = '',
            start_date = '',
            end_date = '',
            reason = '',
        } = params
        this.id = id
        this.start_date = start_date
        this.end_date = end_date
        this.reason = reason
    }
}
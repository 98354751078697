<template>
<div id="app">
<v-app id="admin-timecard-data-output">
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
    </v-snackbar>
    <v-menu
      v-model="menu_month"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-text-field
          v-model="month"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          max-width="100px"
          ></v-text-field>
      </template>
      <v-date-picker
          v-model="month"
          v-on:change="change_calendar"
          type="month"
          format="yyyy年MM月"
          value-format="yyyy-MM"
          :max="max_month"
          locale="ja-jp"
          @input="menu_month = false"
      ></v-date-picker>
    </v-menu>
    <v-menu
      v-model="menu_month_end"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-text-field
          v-model="month_end"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          max-width="100px"
          ></v-text-field>
      </template>
      <v-date-picker
          v-model="month_end"
          v-on:change="change_calendar"
          type="month"
          format="yyyy年MM月"
          value-format="yyyy-MM"
          :max="max_month"
          locale="ja-jp"
          @input="menu_month_end = false"
      ></v-date-picker>
    </v-menu>
    <v-select
        v-model="selected_user"
        :items="filteredData"
        :item-text="item => item.employer_id + ' : ' + item.name + '('+item.retire_date +')'"
        item-value="id"
        label="社員選択"
        multiple
        return-object
        single-line
        @change="change_user"
    ></v-select>
    <v-checkbox
      v-model="hakenlist"
      label="選択リストを派遣社員のみにする">
    </v-checkbox>
    <v-checkbox
      v-model="ignore_retired"
      label="選択リストから退職者除く">
    </v-checkbox>
    <v-radio-group v-model="radioGroup">
    <v-radio
      key=1
      label="PDF出力"
      value=1
    ></v-radio>
    <v-radio
      key=2
      label="CSV出力"
      value=2
    ></v-radio>
  </v-radio-group>

    <v-spacer></v-spacer>
    <v-btn
      color="blue darken-1"
      text
      @click="MonthlyOutput"
    >
      ダウンロード
    </v-btn>

</v-app>
</div>

</template>

<script>
    import * as userAPI from '../../api/admin/userapi.js'
    import * as pdfAPI from '../../api/admin/pdfapi.js'
    import * as timecardAPI from '../../api/admin/timecardapi.js'

    export default {
        components: {
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,
                month: '2021-01', // カレンダーデフォルト値
                month_end: '2021-01', // カレンダーデフォルト値
                max_month:'2021-01',
                table_loading:false,
                menu_month:false,
                menu_month_end:false,
                selected_user:"",
                hakenlist:false,
                ignore_retired:false,
                users: [
                  { name: '', id: -1},
                ],
                radioGroup:1,
            };
        },
        computed: {
          filteredData(){
            return this.users.filter(item => {
              let flag=true;
              if(this.hakenlist){
                if(item.employer_id  < 900){
                  flag=false;
                }
              }
              if(this.ignore_retired){
                if(item.retire_date !=null){
                  flag=false;
                }
              }
              return flag;
            })
          }
        },
        mounted() {
          this.get_users();

          let that = this;
          timecardAPI.get_closed().then( function(closed){
            that.month=closed.substr(0,4)+ '-' + closed.substr(4,2);
            that.month_end=closed.substr(0,4)+ '-' + closed.substr(4,2);
            that.max_month=that.month;
          });

        },
        methods: {
          MonthlyOutput(){
            if(this.radioGroup==1){
              this.MonthlyPdf();
            }else{
              this.MonthlyCsv();
            }
          },
          MonthlyCsv (){

            let user_Ids=new Array();
            let params={
              month:this.month,
              month_end:this.month_end
            };
            if(this.selected_user){
              this.selected_user.forEach(element => {
                user_Ids.push(element.id);
              });
              params={
                  month:this.month,
                  month_end:this.month_end,
                  user: user_Ids
              }
            }
//            pdfAPI.getPDF(params);
            pdfAPI.getCSV(params,this.month.replace('-',''));
          },
          MonthlyPdf (){
            let user_Ids=new Array();
            let params={}
            params={
              month:this.month,
              month_end:this.month_end
            };
            if(this.selected_user){
              this.selected_user.forEach(element => {
                user_Ids.push(element.id);
              });
              params={
                  month:this.month,
                  month_end:this.month_end,
                  user: user_Ids
              }
            }
            pdfAPI.getPDF(params,this.month.replace('-',''));
          },

          change_calendar(){

          },
          change_user(){

          },
          get_users(){
              userAPI.getDatas().then(temp =>{
                  this.users=temp;
              });
          },
          download(){

          }

        }
    }
</script>

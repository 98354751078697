<template>
<div id="userdialog">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
    >
            <v-card>
              <v-card-title>
                <span class="headline">ユーザー情報登録</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="data.employer_id"
                        label="社員番号"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="data.name"
                        label="名前"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="data.name_kana"
                        label="名前カナ"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-card-actions>
                        <v-select
                            v-model="data.division_id"
                            :items="divisions"
                            item-text="name"
                            item-value="id"
                            label="部署選択"
                            single-line
                        ></v-select>
                      </v-card-actions>
                  </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                     カレンダー
                    　<v-spacer></v-spacer>
                      <v-card-actions>
                        <v-data-table 
                          :headers="headers"
                          :items="data.calenders"
                          fixed-header
                          height="100"
                          class="elevation-1"
                          hide-default-footer 
                          disable-pagination
                          dense
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              medium
                              class="mr-2"
                              @click="openCalenderDialog(item)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                              medium
                              class="mr-2"
                              @click="openCalenderDeleteDialog(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>

                        </v-data-table>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="openCalenderDialog(null)"
                        >
                          カレンダー追加
                        </v-btn>
                      </v-card-actions>
                  </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                     勤務型
                    　<v-spacer></v-spacer>
                      <v-card-actions>
                        <v-data-table 
                          :headers="headers"
                          :items="data.work_types"
                          fixed-header
                          height="100"
                          class="elevation-1"
                          hide-default-footer 
                          disable-pagination
                          dense
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              medium
                              class="mr-2"
                              @click="openWorkTypeDialog(item)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                              medium
                              class="mr-2"
                              @click="openWorkTypeDeleteDialog(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>

                        </v-data-table>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="openWorkTypeDialog(null)"
                        >
                          勤務型追加
                        </v-btn>
                      </v-card-actions>
                  </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                     有休
                    　<v-spacer></v-spacer>
                      <v-card-actions>
                        <v-data-table 
                          :headers="headers2"
                          :items="data.paid_holidays"
                          fixed-header
                          height="100"
                          class="elevation-1"
                          hide-default-footer 
                          disable-pagination
                          dense
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              medium
                              class="mr-2"
                              @click="openPaidHolidayDialog(item)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                              medium
                              class="mr-2"
                              @click="openPaidHolidayDeleteDialog(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>

                        </v-data-table>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="openPaidHolidayDialog(null)"
                        >
                          有給付与
                        </v-btn>
                      </v-card-actions>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                     休業
                    　<v-spacer></v-spacer>
                      <v-card-actions>
                        <v-data-table 
                          :headers="headers_temp_leave"
                          :items="data.temp_leaves"
                          fixed-header
                          height="100"
                          class="elevation-1"
                          hide-default-footer 
                          disable-pagination
                          dense
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              medium
                              class="mr-2"
                              @click="openTempLeaveDialog(item)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                              medium
                              class="mr-2"
                              @click="openTempLeaveDeleteDialog(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>

                        </v-data-table>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="openTempLeaveDialog(null)"
                        >
                          休業追加
                        </v-btn>
                      </v-card-actions>
                  </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_hire"
                    v-model="menu_hire"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="hire_date"
                        label="入社日"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-if="menu_hire"
                        v-model="data.hire_date"
                        @input="menu_hire = false;"
                        full-width
                    ></v-date-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_retire"
                    v-model="menu_retire"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="retire_date"
                        label="退職日"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        clearable
                         @click:clear="data.retire_date = null"
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-if="menu_retire"
                        v-model="data.retire_date"
                        @input="menu_retire = false;"
                        full-width
                    ></v-date-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="data.card_id"
                        label="カードID"
                        clearable
                        @click:clear="data.card_id = null"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="data.email"
                        label="メールアドレス"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="data.late_deduction"
                        label="固定残業充当時間"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  登録
                </v-btn>
              </v-card-actions>
            </v-card>
</v-dialog>

<user-calender-dialog ref="calenderDialog" @saved="saveCalender"></user-calender-dialog>
<user-work-type-dialog ref="worktypeDialog" @saved="saveWorkType"></user-work-type-dialog>
<user-paid-holiday-dialog ref="paidholidayDialog" @saved="savePaidHoliday"></user-paid-holiday-dialog>
<user-temp-leave-dialog ref="templeaveDialog" @saved="saveTempLeave"></user-temp-leave-dialog>

</div>

</template>


<script>
    import * as userAPI from '../../../api/admin/userapi.js'
    import * as divisionAPI from '../../../api/admin/divisionapi.js'
    import * as calendarAPI from '../../../api/admin/calendarapi.js'
    import * as worktypeAPI from '../../../api/admin/worktypeapi.js'
    import User from '@internal/models/admin/User'
    import UserCalenderDialog from './UserCalenderDialog'
    import UserWorkTypeDialog from './UserWorkTypeDialog'
    import UserPaidHolidayDialog from './UserPaidHolidayDialog'
    import UserTempLeaveDialog from './UserTempLeaveDialog'
    import CalenderUser from '@internal/models/admin/CalenderUser'
    import UserWorkType from '@internal/models/admin/UserWorkType'
    import UserPaidHoliday from  '@internal/models/admin/UserPaidHoliday'
    import UserTempLeave from  '@internal/models/admin/UserTempLeave'
    

    export default {
        components: {
          UserCalenderDialog,
          UserWorkTypeDialog,
          UserPaidHolidayDialog,
          UserTempLeaveDialog,
        },
        data() {
            return {
              
                menu_hire:false,
                menu_retire:false,
                divisions: [
                  { name: '', id: -1},
                ],
                calenders: [
                  { name: '', id: -1},
                ],
                work_types: [
                  { name: '', id: -1},
                ],
                dialog:false,
                data: new User(),
                editedItem:null,
                editedIndex:'',
                headers:[
                  {
                    text:'編集',
                    sortable:false,
                    value:'actions'
                  },                    
                  {
                    text:'名称',
                    sortable:false,
                    value:'name'
                  },                    
                  {
                    text:'開始日',
                    sortable:false,
                    value:'date'
                  },                    
                ],
                headers2:[
                  {
                    text:'編集',
                    sortable:false,
                    value:'actions'
                  },                    
                  {
                    text:'日数',
                    sortable:false,
                    value:'days'
                  },                    
                  {
                    text:'開始日',
                    sortable:false,
                    value:'date'
                  },                    
                ],
                headers_temp_leave:[
                  {
                    text:'編集',
                    sortable:false,
                    value:'actions'
                  },                    
                  {
                    text:'開始日',
                    sortable:false,
                    value:'start_date'
                  },                    
                  {
                    text:'終了日',
                    sortable:false,
                    value:'end_date'
                  },                    
                ]


            };
        },
        mounted() {
            console.log('Component mounted.')
        },
        computed:{
          hire_date(){
            if(this.data.hire_date){
              let date=new Date(this.data.hire_date.replace(/-/g,"/"))
                return ('0' + (date.getMonth() + 1) ).slice(-2) + "月" + ('0' + date.getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()] + "）";
              }else{
                return "";
              }
          },
          retire_date(){
            if(this.data.retire_date){
              let date=new Date(this.data.retire_date.replace(/-/g,"/"))
                return ('0' + (date.getMonth() + 1) ).slice(-2) + "月" + ('0' + date.getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()] + "）";
              }else{
                return "";
              }
          },
        },
        methods: {
          openCalenderDialog(item){ 
            if(item){
              this.editedIndex = this.data.calenders.indexOf(item)
              this.editedItem = Object.assign({}, item)
            }else{
              this.editedIndex=-1;
              this.editedItem=new CalenderUser;
            }
            this.$refs.calenderDialog.open(this.editedItem);
          },
          openWorkTypeDialog(item){ 
            if(item){
              this.editedIndex = this.data.work_types.indexOf(item)
              this.editedItem = Object.assign({}, item)
            }else{
              this.editedIndex=-1;
              this.editedItem=new UserWorkType;
            }
            this.$refs.worktypeDialog.open(this.editedItem);
          },
          openPaidHolidayDialog(item){ 
            if(item){
              this.editedIndex = this.data.paid_holidays.indexOf(item)
              this.editedItem = Object.assign({}, item)
            }else{
              this.editedIndex=-1;
              this.editedItem=new UserPaidHoliday;
            }
            this.$refs.paidholidayDialog.open(this.editedItem);
          },
          openTempLeaveDialog(item){ 
            if(item){
              this.editedIndex = this.data.temp_leaves.indexOf(item)
              this.editedItem = Object.assign({}, item)
            }else{
              this.editedIndex=-1;
              this.editedItem=new UserTempLeave;
            }
            this.$refs.templeaveDialog.open(this.editedItem);
          },
          openCalenderDeleteDialog(item){
              let Index = this.data.calenders.indexOf(item)
              this.data.calenders.splice(Index,1);
          },
          openWorkTypeDeleteDialog(item){
              let Index = this.data.work_types.indexOf(item)
              this.data.work_types.splice(Index,1);
          },
          openPaidHolidayDeleteDialog(item){
              let Index = this.data.paid_holidays.indexOf(item)
              this.data.paid_holidays.splice(Index,1);
          },
          openTempLeaveDeleteDialog(item){
              let Index = this.data.temp_leaves.indexOf(item)
              this.data.temp_leaves.splice(Index,1);
          },
            open(id){
                this.get_lists();
                this.data= new User();
                if(id){
                    userAPI.getDatabyId(id)
                    .then(result =>{
                        this.data=result;
                        this.dialog=true
                        }
                    );
                }else{
                    this.dialog=true;
                }
            },
            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.data = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            saveCalender(data){
              if (this.editedIndex > -1) {
                Object.assign(this.data.calenders[this.editedIndex], this.editedItem)
              } else {
                this.data.calenders.push(this.editedItem)
              }
            },
            saveWorkType(data){
              if (this.editedIndex > -1) {
                Object.assign(this.data.work_types[this.editedIndex], this.editedItem)
              } else {
                this.data.work_types.push(this.editedItem)
              }
            },
            savePaidHoliday(data){
              if (this.editedIndex > -1) {
                Object.assign(this.data.paid_holidays[this.editedIndex], this.editedItem)
              } else {
                this.data.paid_holidays.push(this.editedItem)
              }
            },
            saveTempLeave(data){
              if (this.editedIndex > -1) {
                Object.assign(this.data.temp_leaves[this.editedIndex], this.editedItem)
              } else {
                this.data.temp_leaves.push(this.editedItem)
              }
            },
            save () {
                userAPI.save(this.data)
                .then(result =>{
                    if(result.status){
                        let message= "ユーザー登録";
                        this.$emit("saved",{type: message});//親に通知してリストを更新させる。
                    }else{
                        
                    }
                })
                this.close()
            },

            get_lists(){
              divisionAPI.getDatas()
              .then(list =>{
                this.divisions=list;
              })
              calendarAPI.getDatas()
              .then(list =>{
                this.calenders=list;
              })
              worktypeAPI.getDatas()
              .then(list =>{
                this.work_types=list;
              })
            },


        }
    }
</script>

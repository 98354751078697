<template>
<div id="user-templeave-dialog">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
    >
            <v-card>
              <v-card-title>
                <span class="headline">休業登録</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-menu
                      ref="menu_start_date"
                      v-model="menu_start_date"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="450px"
                      min-width="450px"
                      >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                          v-model="start_date"
                          label="休業開始日"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-if="menu_start_date"
                          v-model="editedItem.start_date"
                          @input="menu_start_date = false;"
                          full-width
                      ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-menu
                      ref="menu_end_date"
                      v-model="menu_end_date"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="400px"
                      min-width="400px"
                      >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                          v-model="end_date"
                          label="休業終了日"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-if="menu_end_date"
                          v-model="editedItem.end_date"
                          @input="menu_end_date = false;"
                          full-width
                      ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                <v-row>

                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.reason"
                        label="休業理由"
                      >
                      </v-text-field>
                  </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

</div>

</template>


<script>
    import UserTempLeave from '@internal/models/admin/UserTempLeave'
   // import * as paidholidayAPI from '../../../api/admin/paidholidayapi.js'

    export default {
        components: {
        },
        data() {
            return {
                dialog:false,
                menu_start_date:false,
                menu_end_date:false,
                editedItem: new UserTempLeave(),
            };
        },
        mounted() {
        },
        computed:{
          start_date(){
            if(this.editedItem.start_date){
              let date=new Date(this.editedItem.start_date.replace(/-/g,"/"))
                return date.getFullYear() + "年"  + ('0' + (date.getMonth() + 1) ).slice(-2) + "月" + ('0' + date.getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()] + "）";
              }else{
                return "";
              }
          },
          end_date(){
            if(this.editedItem.end_date){
              let date=new Date(this.editedItem.end_date.replace(/-/g,"/"))
                return date.getFullYear() + "年"  + ('0' + (date.getMonth() + 1) ).slice(-2) + "月" + ('0' + date.getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()] + "）";
              }else{
                return "";
              }
          },
        },
        methods: {
          open(data){
            if(data){
              this.editedItem= data;
            }else{
              this.editedItem=new UserTempLeave();
            }
            this.dialog=true;
          },
          close () {
            this.dialog = false
          },
          save () {
            this.$emit("saved",{data: this.editedItem});//親に通知してリストを更新させる。              
            this.close()
          },

        }
    }
</script>
